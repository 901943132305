import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Segmented,
  Select,
  Space,
  Spin,
  Tag,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import './chat.css';
import { apiRequest } from '../../api';
import { ChatMessage } from './ChatMessage';

const teachers = ['Gautama Buddha', 'Lao Tzu', 'Mahavira', 'Confucius', 'Zoroaster', 'Jesus', 'St Paul', 'St Augustine', 'Muhammad', 'Shankara', 'Ramanuja', 'Hildegard of Bingen', 'Meister Eckhart', 'Rumi', 'Teresa of Ávila', 'Guru Nanak', 'Ignatius of Loyola', 'Francis de Sales', 'Jacob Boehme', 'William Law', 'Emanuel Swedenborg', 'Jean-Jacques Rousseau', 'Ramakrishna', 'Swami Vivekananda', 'G I Gurdjieff', 'Meher Baba', 'Paramahansa Yogananda', 'Martin Buber', 'Thomas Merton', 'Pope John XXIII', 'Alan Watts', 'Thich Nhat Hanh', 'Joseph Campbell', 'Mary Baker Eddy', 'Swami Sivananda', 'Dalai Lama', 'Jiddu Krishnamurti', 'Pope John Paul II', 'Swami Rama', 'Eckhart Tolle', 'Deepak Chopra', 'Andrew Cohen', 'David Deida', 'Neale Donald Walsch', 'Dzigar Kongtrul Rinpoche', 'Pema Chödrön', 'Adyashanti', 'Lama Surya Das', 'Mooji', 'Byron Katie']

const suggestions: string[] = [
  'What is the purpose of life?',
  'What is love?',
  'Who am I?',
  'What is beauty?',
  'What is my purpose?',
  'What is the essence of life?',
  'What is a universal truth?',
  'What is a human soul?',
  'What is a human mind?',
  'What is the nature of reality?',
  'What is the meaning of existence?',
  'What is the meaning of life?',
  'What is the purpose of suffering?',
  'What is the power of love?',
  'What is the nature of consciousness?',
  'How can I find inner peace?',
  'How can I find spiritual enlightenment?',
  'What is the importance of meditation?',
  'How do I create a strong spiritual connection?',
  'What is the power of forgiveness?',
  'How can I live a more spiritual life?',
  'What is the relationship between faith and reality?',
  'What is the key to inner transformation?',
  'How can I become more self-aware?',
  'How can I be more mindful?',
  'What is the essence of spiritual growth?',
  'What is the source of true happiness?',
  'How can I break free of my ego?', 'How can I deepen my spiritual practice?'
];

const books: string[] = ['Bible', 'Tao Te Ching (Lao Tzu)', 'Analects of Confucius', 'Bhagavad Gita', 'Upanishads', 'Dhammapada', 'Book of Proverbs', "Qur'an", 'The Tibetan Book of the Dead', 'The New Testament'];

type Message = {
  id: string;
  my?: boolean;
  author: string;
  message: string;
}

type ChatInputData = {
  context: {
    teachers?: string[];
    books?: string[];
  },
  message: string,
  conversationId?: string | null,
  parentMessageId?: string | null,
}

const makeChatApiRequest = async (
  data: ChatInputData,
): Promise<{
  messages: Message[];
  conversationId: string;
  messageId: string;
}> => {
  return apiRequest({ path: 'chat', body: data });
};

type FormData = { teachers?: string[], books?: string[], question: string };


enum AskTarget {
  TEACHERS = 'Teachers',
  BOOKS = 'Books',
}

export const Chat = () => {
  const [form] = Form.useForm();
  const [message, setMessage] = useState<string>('');
  const [messages, setMessages] = useState<Message[]>([]);

  const [allSuggestions, setAllSugguestions] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [askTarget, setAskTarget] = useState<AskTarget>(AskTarget.TEACHERS);

  const conversationId = useRef<string|null>(null);
  const lastMessageId = useRef<string|null>(null);
  const messagesEl = useRef<HTMLDivElement>(null);

  const isSmallScreen = document.documentElement.clientWidth < 600;
  const MAX_SUGGESTIONS = isSmallScreen ? 3 : 10;

  useEffect(() => {
    messagesEl.current?.scrollTo({
      top:messagesEl.current?.scrollHeight,
    });
  }, [messages]);

  const handleSendMessage = async (values: FormData) => {
    const { teachers, books } = values;

    setLoading(true);

    console.log('Submit', teachers, books);

    try {
      setMessages((prev) => {
        return prev.concat([{
          id: Math.random().toString(16).slice(4),
          author: 'me',
          my: true,
          message,
        }]);
      });

      const response = await makeChatApiRequest({
        context: { teachers, books },
        message,
        conversationId: conversationId.current,
        parentMessageId: lastMessageId.current,
      });

      setMessage('');
      setMessages((prev) => ([
        ...prev,
        ...response.messages,
      ]));

      conversationId.current = response.conversationId;
      lastMessageId.current = response.messageId;

    } catch (error) {
      console.log('error', error);;
      Modal.error({
        title: 'Error',
        content: (error as any)?.message ?? 'Unknown',
      });
    }
    setLoading(false);
  };


  return (
    <>
      <Form
        form={form}
        name="basic"
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        style={{  }}
        initialValues={{ }}
        onFinish={handleSendMessage}
        autoComplete="off"
      >
        <h1 style={{ textAlign: 'center' }}>Chat</h1>

        <div className="container">
          <div className="row">
            <section className="chat">
              <div className="messages-chat" ref={messagesEl}>
                {messages.map((msg, index) => (
                  <ChatMessage
                    key={`msg-${index}`}
                    author={msg.author}
                    message={msg.message}
                    side={msg.my ? 'right' : 'left'}
                    date="dsad"
                  />
                ))}
              </div>

              <div style={{ marginBottom: 16 }}>
                <Segmented options={[AskTarget.TEACHERS, AskTarget.BOOKS]} value={askTarget} onChange={(v) => setAskTarget(v as AskTarget)} />
              </div>

              {askTarget === AskTarget.TEACHERS && (
                <Form.Item
                  label="Teachers"
                  name="teachers"
                  rules={[{ required: true, message: 'Please select at least one influencers' }]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select one or more Spiritual Influencer(s)"
                  >
                    {teachers.map((teacher) => (
                      <Select.Option key={teacher} valu={teacher}>{teacher}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              {askTarget === AskTarget.BOOKS && (
                <Form.Item
                  label="Books"
                  name="books"
                  rules={[{ required: true, message: 'Please select one or more books' }]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select one or more books"
                  >
                    {books.map((book) => (
                      <Select.Option key={book} valu={book}>{book}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              <Form.Item>
                <Divider orientation="left">Suggestions</Divider>
                <Space size={[0, 8]} wrap>
                  {suggestions.slice(0, allSuggestions ? suggestions.length : MAX_SUGGESTIONS).map((suggestion) => (
                    <Tag
                      key={suggestion}
                      onClick={() => {
                        setMessage(suggestion);
                      }}
                      style={{ cursor: 'pointer' }}
                      color="orange">{suggestion}</Tag>
                  ))}
                  {allSuggestions
                    ? (
                      <Button size="small" onClick={() => setAllSugguestions(false)}>Hide</Button>
                    )
                    : (
                      <Button size="small" onClick={() => setAllSugguestions(true)}>Show {suggestions.length - 10} more...</Button>
                    )
                  }
                </Space>
              </Form.Item>

              <div className="footer-chat">
                <input type="text" className="write-message"
                       placeholder="Type your question here"
                       value={message}
                       onChange={(e) => setMessage(e.target.value)}
                />
                <Button
                  htmlType="submit"
                  disabled={message.trim().length === 0 || loading}
                >
                  {loading ? 'Sending...' : 'Send'}
                </Button>
              </div>
            </section>
          </div>
        </div>
      </Form>
    </>
  );
}
