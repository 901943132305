import React  from 'react';
import 'antd/dist/reset.css';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './Router';


function App() {

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}

export default App;
