import { Typography } from 'antd';
import React from 'react';

export const About: React.FC = () => {
  return (
    <div>
      <h1>Why did we create Joytime?</h1>
      <Typography.Text>
        At joytime, we believe that life is meant to be lived with peace, joy, love, and beauty. We created joytime to promote this vision by connecting our audience to people, teachings, and products that help bring these values to life with the help of the latest technologies. Together, let us fill our lives with joy and create a more harmonious and brighter future.
      </Typography.Text>
    </div>
  );
};
