import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Menu, Layout as AntLayout, Col, Row } from 'antd';
const { Content, Footer, Header } = AntLayout;

export const Layout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  console.log('location, ', location);

  const menuLinks = [
    {
      key: '/',
      label: `Q&A`,
      onClick: () => navigate('/')
    },
    {
      key: '/chat',
      label: `Chat`,
      onClick: () => navigate('/chat'),
    },
    {
      key: 'teachers',
      label: 'Teachers',
      onClick: () => navigate('/teachers')
    },
    {
      key: 'books',
      label: 'Books',
      onClick: () => navigate('/books')
    },
    {
      key: 'about',
      label: `About`,
      onClick: () => navigate('/about')
    },
  ];

  const activePage = menuLinks.find((v) => `/${v.key}` === location.pathname);

  return (
    <AntLayout className="layout">
      <Header>
        <Menu
          mode="horizontal"
          defaultSelectedKeys={activePage ? [activePage.key] : []}
          items={menuLinks}
        />
      </Header>
      <Content style={{ padding: '0 50px', background: '#fff' }}>
        <Row>
          <Col xs={{
            span: 24,
            offset: 0,
          }} lg={{
            span: 12,
            offset: 6
          }} style={{ paddingTop: 20 }}>
            <Outlet />
          </Col>
        </Row>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Powered by OpenAI &copy; 2023</Footer>
    </AntLayout>
  );
};
