const API_URL = window.location.host.startsWith('localhost')
  ? 'http://localhost:3001'
  : 'https://openai-spiritual-teachers-api.vercel.app';

type RequestParams<Input> = {
  method?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE' | string,
  path: string,
  body?: Input,
};

export const apiRequest = async <Input = any, Result = any> ({
  method = 'POST',
  path,
  body,
}: RequestParams<Input>): Promise<Result> => {
  const response = await fetch(`${API_URL}/${path}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: body ? JSON.stringify(body) : undefined,
  });
  if (!response.ok) {
    console.log('response', response);
    throw new Error(`Request failed: ${response.statusText}`);
  }
  return response.json();
};
