import React from 'react';
import { Route, Routes } from "react-router-dom";
import { About } from './pages/About';
import { Chat } from './pages/chat/Chat';
import { Home } from './pages/Home';
import { Layout } from './Layout';
import { Teachers } from './pages/Teachers';
import { Books } from './pages/Books';

function NoMatch() {
  return <h1>404</h1>;
}

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="chat" element={<Chat />} />
        <Route path="about" element={<About />} />
        <Route path="teachers" element={<Teachers />} />
        <Route path="books" element={<Books />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
};
