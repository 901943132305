import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Segmented,
  Select,
  Space,
  Spin,
  Tag,
} from 'antd';
import React, { useState } from 'react';
import { apiRequest } from '../api';


const teachers = ['Gautama Buddha', 'Lao Tzu', 'Mahavira', 'Confucius', 'Zoroaster', 'Jesus', 'St Paul', 'St Augustine', 'Muhammad', 'Shankara', 'Ramanuja', 'Hildegard of Bingen', 'Meister Eckhart', 'Rumi', 'Teresa of Ávila', 'Guru Nanak', 'Ignatius of Loyola', 'Francis de Sales', 'Jacob Boehme', 'William Law', 'Emanuel Swedenborg', 'Jean-Jacques Rousseau', 'Ramakrishna', 'Swami Vivekananda', 'G I Gurdjieff', 'Meher Baba', 'Paramahansa Yogananda', 'Martin Buber', 'Thomas Merton', 'Pope John XXIII', 'Alan Watts', 'Thich Nhat Hanh', 'Joseph Campbell', 'Mary Baker Eddy', 'Swami Sivananda', 'Dalai Lama', 'Jiddu Krishnamurti', 'Pope John Paul II', 'Swami Rama', 'Eckhart Tolle', 'Deepak Chopra', 'Andrew Cohen', 'David Deida', 'Neale Donald Walsch', 'Dzigar Kongtrul Rinpoche', 'Pema Chödrön', 'Adyashanti', 'Lama Surya Das', 'Mooji', 'Byron Katie']

const suggestions: string[] = [
  'What is the purpose of life?',
  'What is love?',
  'Who am I?',
  'What is beauty?',
  'What is my purpose?',
  'What is the essence of life?',
  'What is a universal truth?',
  'What is a human soul?',
  'What is a human mind?',
  'What is the nature of reality?',
  'What is the meaning of existence?',
  'What is the meaning of life?',
  'What is the purpose of suffering?',
  'What is the power of love?',
  'What is the nature of consciousness?',
  'How can I find inner peace?',
  'How can I find spiritual enlightenment?',
  'What is the importance of meditation?',
  'How do I create a strong spiritual connection?',
  'What is the power of forgiveness?',
  'How can I live a more spiritual life?',
  'What is the relationship between faith and reality?',
  'What is the key to inner transformation?',
  'How can I become more self-aware?',
  'How can I be more mindful?',
  'What is the essence of spiritual growth?',
  'What is the source of true happiness?',
  'How can I break free of my ego?', 'How can I deepen my spiritual practice?'
];

const books: string[] = ['Bible', 'Tao Te Ching (Lao Tzu)', 'Analects of Confucius', 'Bhagavad Gita', 'Upanishads', 'Dhammapada', 'Book of Proverbs', "Qur'an", 'The Tibetan Book of the Dead', 'The New Testament'];

const makeChatApiRequest = async (text = ''): Promise<{ data: string }> => {
  return apiRequest({ path: 'ask', body: { text } });
};

type FormData = { teachers?: string[], books?: string[], question: string };


enum AskTarget {
  TEACHERS = 'Teachers',
  BOOKS = 'Books',
}

export const Home = () => {
  const [form] = Form.useForm();
  const [answers, setAnswers] = useState<Record<string, any> | null>(null);
  const [allSuggestions, setAllSugguestions] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [askTarget, setAskTarget] = useState<AskTarget>(AskTarget.TEACHERS);

  const isSmallScreen = document.documentElement.clientWidth < 600;
  const MAX_SUGGESTIONS = isSmallScreen ? 3 : 10;

  const handleFinish = async (values: FormData) => {
    const { teachers, books, question } = values;

    let prompt = '';

    if (askTarget === AskTarget.TEACHERS && teachers?.length) {
      let compareStr = '';
      if (teachers.length > 1) {
        compareStr = ' and compare the answers'
      }
      prompt = `Ask ${teachers.join(',')} about "${question}"${compareStr}. Format response as json object where key is name and value is answer`;
    } else if (askTarget === AskTarget.BOOKS && books?.length) {
      let compareStr = '';
      let bookNamePostfix = '';
      if (books.length > 1) {
        compareStr = ' and compare the answers'
        bookNamePostfix = 'book name';
      } else {
        bookNamePostfix = `"${books[0]}"`;
      }
      prompt = `Ask ${books.join(',')} about "${question}"${compareStr}. Format response as json object where key is ${bookNamePostfix} and value is answer`;
    }

    setLoading(true);

    try {
      const response = await makeChatApiRequest(prompt);
      const aiAnswers = JSON.parse(response.data);
      setAnswers(aiAnswers);
    } catch (error) {
      Modal.error({
        title: 'Error',
        content: (error as any)?.message ?? 'Unknown',
      });
    }
    setLoading(false);
  };


  return (
    <>
      <Form
        form={form}
        name="basic"
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600, margin: '0 auto' }}
        initialValues={{ }}
        onFinish={handleFinish}
        autoComplete="off"
      >
        <h1 style={{ textAlign: 'center' }}>Ask Panel of Spiritual Teachers/Books</h1>

        <div style={{ marginBottom: 16 }}>
          <Segmented options={[AskTarget.TEACHERS, AskTarget.BOOKS]} value={askTarget} onChange={(v) => setAskTarget(v as AskTarget)} />
        </div>

        {askTarget === AskTarget.TEACHERS && (
          <Form.Item
            label="Teachers"
            name="teachers"
            rules={[{ required: true, message: 'Please select at least one influencers' }]}
          >
            <Select
              mode="multiple"
              placeholder="Select one or more Spiritual Influencer(s)"
            >
              {teachers.map((teacher) => (
                <Select.Option key={teacher} valu={teacher}>{teacher}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {askTarget === AskTarget.BOOKS && (
          <Form.Item
            label="Books"
            name="books"
            rules={[{ required: true, message: 'Please select one or more books' }]}
          >
            <Select
              mode="multiple"
              placeholder="Select one or more books"
            >
              {books.map((book) => (
                <Select.Option key={book} valu={book}>{book}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label="Question"
          name="question"
          rules={[{ required: true, message: 'Please ask a question' }]}
        >
          <Input placeholder="Type your question" />
        </Form.Item>

        <Form.Item>
          <Divider orientation="left">Suggestions</Divider>
          <Space size={[0, 8]} wrap>
            {suggestions.slice(0, allSuggestions ? suggestions.length : MAX_SUGGESTIONS).map((suggestion) => (
              <Tag
                key={suggestion}
                onClick={() => {
                  form.setFieldValue('question', suggestion);
                }}
                style={{ cursor: 'pointer' }}
                color="orange">{suggestion}</Tag>
            ))}
            {allSuggestions
              ? (
                <Button size="small" onClick={() => setAllSugguestions(false)}>Hide</Button>
              )
              : (
                <Button size="small" onClick={() => setAllSugguestions(true)}>Show {suggestions.length - 10} more...</Button>
              )
            }
          </Space>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" loading={loading} htmlType="submit">
            Ask
          </Button>
        </Form.Item>
      </Form>

      <Divider />
      {loading && (
        <Row justify="center">
          <Col span={1}>
            <Spin size="large" />
          </Col>
        </Row>
      )}
      {answers && (
        <Row gutter={[16, 16]} wrap>
          {Object.keys(answers).map((teacher) => (
            <Col key={teacher} xs={24} md={12} lg={12}>
              <Card title={teacher} bordered={false}>
                {answers[teacher]}
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
}
