import React from 'react';

export interface ChatMessageProps {
  side: 'left' | 'right';
  author: string;
  message: string;
  date: string;
}

export const ChatMessage: React.FC<ChatMessageProps> = ({ author,  message, date, side }) => {
  if (side === 'left') {
    return (
      <>
        <div className="message">
          <div className="photo" style={{
            backgroundImage: 'url(https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80)'
          }}>
          </div>
          <p className="text">
            <p style={{ fontWeight: 'bold' }}>{author}:</p>
            {message}
          </p>
        </div>
        <p className="time"> 15h09</p>
      </>
    );
  }
  return (
    <div className="message text-only">
      <div className="response">
        <p className="text">{message}</p>
      </div>
    </div>
  );
};
